import { Component, Inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';
import { Progress } from '@unifii/sdk';
import { Observable, Subscription } from 'rxjs';

import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';

export interface IndexProgressData {
    progress: Observable<Progress>;
}

@Component({
    templateUrl: './index-progress.html',
    styleUrls: ['./index-progress.less'],
})
export class IndexProgressComponent implements Modal<IndexProgressData, boolean> {

    version: string | null;
    progress = 0;
    error: AppError | undefined;
    completed: boolean;

    private subscription: Subscription;

    constructor(
        public runtime: ModalRuntime<IndexProgressData, boolean>,
        @Inject(ModalData) public data: { progress: Observable<Progress> },
        private errorService: ErrorService,
    ) {
        this.update();
    }

    update() {

        if (this.subscription != null) {
            this.subscription.unsubscribe();
        }

        this.subscription = this.data.progress.subscribe(
            (progress) => {
                this.progress = progress.done / progress.total;
                this.version = progress.id ?? null;
            },
            (error) => {
                // todo: translate error message!
                this.error = this.errorService.createError('Index failed to updated', error);
            },
            () => this.close());
    }

    close() {
        this.runtime.close(this.error == null);
    }

}
