import { Inject, Injectable, Optional } from '@angular/core';
import { ModalService } from '@unifii/library/common';
import { Progress } from '@unifii/sdk';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Config } from 'config';
import { ErrorService } from 'shell/errors/error.service';
import { ContentLoader } from 'shell/offline/content-loader';
import { ContentDb, IndexedDbWrapper, TenantDb } from 'shell/offline/indexeddb-wrapper';
import { ContentInfo, ContentState, ContentStores, TenantStores, checkUpdateType, getProjectNames, getStepsDone } from 'shell/offline/offline-model';
import { OnlineContentLoader } from 'shell/offline/online-content-loader.service';
import { PostUpdateHook } from 'shell/offline/post-update-hook';
import { Authentication } from 'shell/services/authentication';

import { IndexProgressComponent, IndexProgressData } from './components/index-progress.component';

@Injectable()
export class OnlineManager {

    constructor(
        @Inject(Config) private config: Config,
        @Inject(TenantDb) private tenantDb: IndexedDbWrapper,
        @Inject(ContentDb) private contentDb: IndexedDbWrapper,
        private loader: OnlineContentLoader,
        private modalService: ModalService,
        @Inject(Authentication) private authentication: Authentication,
        @Optional() @Inject(PostUpdateHook) private postUpdateHook: PostUpdateHook,
        private errorService: ErrorService,
    ) { }

    /**
     *  Override the default ContentLoader for this execution
     */
    async updateContent(override?: ContentLoader): Promise<boolean> {
        const loader = override ? override : this.loader;

        const existingVersion = await this.getContentInfo();
        const nextVersion = await this.updateAvailable(loader);

        // Guard local version up to date
        if (!nextVersion) {
            console.log('OnlineContent: No new content, abort indexing');
            if (!existingVersion) {
                throw Error('No existing or installable content!');
            }

            return true;
        }

        // start updating index
        const updated = await this.modalService
            .openFit<IndexProgressData, boolean>(
                IndexProgressComponent,
                { progress: this.indexContent(existingVersion, nextVersion) },
                { guard: true },
            );

        if (!updated) {
            /**
             * If no existing database we have no choice but to logout
             */
            return this.authentication.logout();
        }

            return updated;
    }

    /**
     * @param override Override the default ContentLoader for this execution
     * Verify if there is a newer content valid to be installed
     */
    async updateAvailable(override?: ContentLoader): Promise<ContentInfo | null> {

        const loader = override ? override : this.loader;

        try {
            const from = await this.getContentInfo();

            const to = await loader.getLatestInfo();

            const updateType = checkUpdateType(from, to, this.config.unifii.preview);

            if (updateType != null) {
                return to;
            }
        } catch (e) { /* empty */ }

        return null;
    }

    /**
     * Open and store a connection to the current project DB
     * Project DB must be compatible with the App Config (preview content flag)
     * Project DB must be registered as Active under TenantDb.Projects
     * Project DB must be present in IndexedDb
     */
    async openContentDB(): Promise<IDBDatabase> {

        // Single promise reference guard
        if (this.contentDb.db) {
            return this.contentDb.db;
        }

        const info = await this.getContentInfo();

        if (!info) {
            throw new Error(`ContentDB is not registered within the tenant`);
        }

        // App configured for stable can not access preview content
        if (!this.config.unifii.preview && info.preview) {
            throw new Error(`ContentDB is not compatible with the app configuration, preview mismatch`);
        }

        this.contentDb.db = new Promise<IDBDatabase>((resolve, reject) => {

            const names = getProjectNames(info);
            const request = indexedDB.open(names.db, 2);

            request.onsuccess = (e: any) => {

                console.log('OnlineContent: ContentDd opened!');

                const db: IDBDatabase = e.currentTarget.result;

                db.onversionchange = (x: any) => {
                    console.log('OnlineContent: ContentDB ' + names.db + ' versionchange ' + x.oldVersion + ' > ' + x.newVersion);
                    db.close();
                };

                resolve(e.currentTarget.result);
            };

            request.onerror = () => {
                // Version point to a corrupted DB... remove the version reference
                this.tenantDb.delete(TenantStores.Projects, info.projectId).then(() => {
                    reject('ContentDB error open DB');
                });
            };

            request.onupgradeneeded = (e: any) => {

                const database: IDBDatabase = request.result;

                if (e.oldVersion === 0) {
                    // Version point to a non existing DB... remove the version reference
                    this.tenantDb.delete(TenantStores.Projects, info.projectId).then(() => {
                        reject(`ContentDB doesn't exists`);
                    });
                } else {
                    if (e.oldVersion === 1) {
                        database.createObjectStore(ContentStores.FormVersions);
                    }
                }
            };
        });

        return this.contentDb.db;
    }

    /** Get ContentInfo of the current project */
    async getContentInfo(): Promise<ContentInfo> {

        await this.openTenantDB();

        return this.tenantDb.get<ContentInfo>(TenantStores.Projects, this.config.unifii.projectId);
    }

    private openTenantDB(): Promise<IDBDatabase> {

        // Single promise reference
        if (this.tenantDb.db) {
            return this.tenantDb.db;
        }

        this.tenantDb.db = new Promise((resolve, reject) => {

            const dbName = `content-${this.config.unifii.tenant}`;
            const request = indexedDB.open(dbName, 1);

            request.onsuccess = (e) => {

                const db: IDBDatabase = (e as any).currentTarget.result;

                db.onversionchange = (x: any) => {
                    console.log(`OnlineContent: TenantDb ${dbName} versionchange ${x.oldVersion} > ${x.newVersion}`);
                    db.close();
                };

                resolve(db);
            };

            request.onupgradeneeded = (e) => {
                const database = (e as any).currentTarget.result;

                database.createObjectStore(TenantStores.Assets);
                database.createObjectStore(TenantStores.Projects, { keyPath: 'projectId' });
                database.createObjectStore(TenantStores.Versions);
            };

            request.onerror = reject;
        });

        return this.tenantDb.db;
    }

    private openProjectDB(info: ContentInfo, collections?: string[]): Promise<IDBDatabase> {

        return new Promise((resolve, reject) => {

            const names = getProjectNames(info);
            const request = indexedDB.open(names.db, 2);

            request.onsuccess = (e) => {

                const db: IDBDatabase = (e as any).currentTarget.result;

                db.onversionchange = (x: any) => {
                    console.log(`OnlineContent: ProjectDb ${names.db} versionchange ${x.oldVersion} > ${x.newVersion}`);
                    db.close();
                };

                resolve(db);
            };

            request.onupgradeneeded = () => {

                console.log('OnlineContent: Created NEW ContentDB!');
                const database: IDBDatabase = request.result;

                // Version 1
                database.createObjectStore(ContentStores.Collections, { keyPath: 'identifier' });
                database.createObjectStore(ContentStores.Forms, { keyPath: 'identifier' });
                database.createObjectStore(ContentStores.Views, { keyPath: 'id' });
                database.createObjectStore(ContentStores.ViewDefinitions, { keyPath: 'identifier' });
                database.createObjectStore(ContentStores.Pages, { keyPath: 'id' });
                database.createObjectStore(ContentStores.Buckets, { keyPath: 'bucket' });
                database.createObjectStore(ContentStores.Structure, { autoIncrement: true });
                database.createObjectStore(ContentStores.Assets, { autoIncrement: true });
                database.createObjectStore(ContentStores.Indexes);

                for (const collection of (collections ?? [])) {
                    database.createObjectStore(collection, { keyPath: 'id' });
                }

                // Version 2
                database.createObjectStore(ContentStores.FormVersions);

            };

            request.onerror = reject;
        });
    }

    private deleteDatabase(name: string): Promise<boolean> {

        return new Promise<boolean>((resolve, reject) => {
            const request = indexedDB.deleteDatabase(name);

            request.onsuccess = () => { resolve(true); };
            request.onerror = (error) => { console.log('OnlineContent: deleteDatabase error', error); reject(error); };
            request.onblocked = (error) => { console.log('OnlineContent: deleteDatabase blocked', error); reject(error); };
        });
    }

    /**
     * Progress milestones:
     * 5% - Start
     * 20% - Content loaded
     * 80% - Indexes generated
     * 100% - Completed
     */
    private indexContent(existingVersion: ContentInfo, nextVersion: ContentInfo): Observable<Progress> {

        return new Observable((observer) => {

            (async() => {

                console.log(`OnlineContent: Load content of version ${nextVersion.name} STARTED`);
                observer.next(getStepsDone(nextVersion.name, 0, 5));

                // Guarantee there is no projectDB for the update target version (blocking failure)
                await this.deleteDatabase(getProjectNames(nextVersion).db);

                // Load CMS Content
                const content = await this.loader.load(nextVersion);

                observer.next(getStepsDone(nextVersion.name, 5, 20));

                // Create DB for Project contet
                const nextContentDB = new IndexedDbWrapper(this.errorService);

                nextContentDB.db = this.openProjectDB(
                    content.info,
                    content.collections.map((collection) => collection.definition.identifier),
                );

                // Register DB as Next in Versions (temporary)
                await this.tenantDb.put<ContentInfo>(
                    TenantStores.Versions,
                    Object.assign(content.info, { state: ContentState.Next }),
                    getProjectNames(content.info).key,
                );
                console.log(`OnlineContent: Created new projectDb for ${content.info.name}`);

                // Mark to be removed
                if (existingVersion) {
                    console.log(`OnlineContent: Marked previous content ${existingVersion.name} to be deleted`);
                    await this.tenantDb.put<ContentInfo>(
                        TenantStores.Versions,
                        Object.assign(existingVersion, { state: ContentState.Delete }),
                        getProjectNames(existingVersion).key,
                    );
                }

                // Complete
                observer.next(getStepsDone(nextVersion.name, 20, 80));

                // Register stored DB as official project content DB
                await this.tenantDb.put<ContentInfo>(
                    TenantStores.Projects,
                    Object.assign(content.info, { state: ContentState.Active }),
                );
                console.log(`OnlineContent: Version ${content.info.name} activated into TenantDb`);

                // Delete (temporary) entry as Next from Versions
                await this.tenantDb.delete(TenantStores.Versions, getProjectNames(content.info).key);

                // Official ContentDB point to new DB
                console.log(`OnlineContent: Switch ContentDb reference to the new installed DB`);
                this.contentDb.db = nextContentDB.db;

                console.log(`OnlineContent: Installation of version ${nextVersion.name} FINISHED`);
                const start = performance.now();

                await this.postUpdateHook.run(nextContentDB, content)
                    .pipe(tap((progress) => observer.next(getStepsDone(content.info.name, 80, 100, progress))))
                    .toPromise();
                console.log(`OnlineContent: Indexes generate completed in ${(performance.now() - start).toFixed(0)}ms`);

                observer.next(getStepsDone(nextVersion.name, 80, 100));

                observer.complete();

            })();
        });
    }

}
