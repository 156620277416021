import { NgModule, Type } from '@angular/core';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { DisplayModule } from '@unifii/library/smart-forms/display';

import { DiscoverModule } from 'discover/discover.module';
import { ContentComponentSelector } from 'shell/content/content-component-selector';
import { ContentDb } from 'shell/offline/indexeddb-wrapper';
import { PostUpdateHook } from 'shell/offline/post-update-hook';
import { ShellModule } from 'shell/shell.module';
import { CustomPageComponent } from 'unifii-helpdocs/components/custom-page.component';
import { CustomViewComponent } from 'unifii-helpdocs/components/custom-view.component';
import { IndexProgressComponent } from 'unifii-helpdocs/components/index-progress.component';
import { UnifiiHelpDocsMainComponent } from 'unifii-helpdocs/components/main.component';
import { UnifiiHelpDocsSettingsComponent } from 'unifii-helpdocs/components/settings.component';
import { UnifiiHelpDocsComponentSelector } from 'unifii-helpdocs/components/unifii-helpdocs-component-selector';
import { OnlineManager } from 'unifii-helpdocs/online-manager';
import { UnifiiHelpDocsRouteReuseStrategy } from 'unifii-helpdocs/route-reuse-strategy';
import { HeaderConverter } from 'unifii-helpdocs/services/header-converter.service';
import { IndexesService } from 'unifii-helpdocs/services/indexes-service';
import { UnifiiHelpDocsContentSearcher } from 'unifii-helpdocs/unifii-helpdocs-content-searcher';
import { UnifiiHelpDocsPostUpdateHook } from 'unifii-helpdocs/unifii-helpdocs-post-update-hook';

import { HelpDocsIndexedDbWrapper } from './services/unifii-helpdocs-indexeddb-wrapper';

export const routeReuseStrategy: Type<RouteReuseStrategy> = UnifiiHelpDocsRouteReuseStrategy;

@NgModule({
    declarations: [CustomPageComponent, CustomViewComponent, UnifiiHelpDocsMainComponent, IndexProgressComponent, UnifiiHelpDocsSettingsComponent],
    imports: [ShellModule, DiscoverModule, RouterModule],
    exports: [DisplayModule],
    providers: [
        UnifiiHelpDocsContentSearcher,
        IndexesService,
        HeaderConverter,
        OnlineManager,
        { provide: ContentDb, useClass: HelpDocsIndexedDbWrapper },
        { provide: PostUpdateHook, useClass: UnifiiHelpDocsPostUpdateHook },
        { provide: ContentComponentSelector, useClass: UnifiiHelpDocsComponentSelector },
    ],
})
export class UnifiiHelpModule { }
