@if (!error) {
	<div class="content">
		<ud-loading-bar [progress]="progress" />
		<p>Updating Index</p>
		<div class="fieldset-item row justify-center">
			<span>Version:</span>
			<span>{{ version }}</span>
		</div>
	</div>
}
@if (error) {
	<uf-blockquote [content]="error.message" class="error" icon="error">
		<h3>{{ error.code }}</h3>
		<!-- todo: use form-actions class once new library merged -->
		<div class="row">
			<button (click)="close()" type="button" class="uf-button--tertiary right" style="margin-right: 0.5rem">Close</button>
			<button (click)="update()" type="button" class="uf-button">Retry</button>
		</div>
	</uf-blockquote>
}
