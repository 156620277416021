import { Injectable, Type } from '@angular/core';

import { ShellContentComponentSelector } from 'shell/content/content-component-selector';
import { PageContent, ViewContent } from 'shell/content/content-types';
import { CustomPageComponent } from 'unifii-helpdocs/components/custom-page.component';
import { CustomViewComponent } from 'unifii-helpdocs/components/custom-view.component';

@Injectable()
export class UnifiiHelpDocsComponentSelector extends ShellContentComponentSelector {

	override getPageComponent(): Type<PageContent> {
		return CustomPageComponent;
	}

	override getViewComponent(): Type<ViewContent> {
		return CustomViewComponent;
	}

}
