<div *ngIf="!error" class="content">
    <ud-loading-bar [progress]="progress"></ud-loading-bar>
    <p>Updating Index</p>
    <div class="fieldset-item row justify-center">
        <span>Version:</span>
        <span>{{version}}</span>
    </div>
</div>
<uf-blockquote *ngIf="error" class="error" icon="error" [content]="error.message">
    <h3>{{ error.code }}</h3>
    <!-- todo: use form-actions class once new library merged -->
    <div class="row">
        <button type="button" class="uf-button--tertiary right" style="margin-right: .5rem;" (click)="close()">
            Close
        </button>
        <button type="button" class="uf-button" (click)="update()">
            Retry
        </button>
    </div>
</uf-blockquote>