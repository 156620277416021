import { Inject, Injectable } from '@angular/core';

import { ContentDb } from 'shell/offline/indexeddb-wrapper';
import { ContentStores } from 'shell/offline/offline-model';
import { getTokens } from 'shell/offline/text-tokenizer';
import { HelpDocsIndexedDbWrapper } from 'unifii-helpdocs/services/unifii-helpdocs-indexeddb-wrapper';

@Injectable()
export class UnifiiHelpDocsContentSearcher {

    constructor(
        @Inject(ContentDb) private contentDb: HelpDocsIndexedDbWrapper,
    ) { }

    async lookup(terms: string): Promise<string[]> {

        // Get text tokens
        const tokens = getTokens(terms);

        let results: { value: string; priority: number }[] = [];

        for (const token of tokens) {

            // first token is results
            if (tokens.indexOf(token) === 0) {
                results.push(...await this.findTokenEntries(token));
            }

            // inner join token results with first token results
            const entries = await this.findTokenEntries(token);

            results = results.filter((r) => entries.find((e) => e.value === r.value));

        }

        // sort by priority
        return results.sort((a, b) => a.priority - b.priority).map((i) => i.value);
    }

    private async findTokenEntries(token: string): Promise<{ value: string; priority: number }[]> {

        const entries: { value: string; priority: number }[] = [];

        // find keyIds that start with token
        const keyRange = IDBKeyRange.bound(token, token + 'uffff', false);
        const keyIds = await this.contentDb.getAllKeys<string[]>(ContentStores.Indexes, keyRange);

        // flattened results for all keyIds
        const keysList: string[] = [];

        for (const keyId of keyIds) {
            const keys = await this.contentDb.get<string[]>(ContentStores.Indexes, keyId) || [];

            keysList.push(...keys);
        }

        // store results by priority of importance
        let priority: number;

        for (const key of keysList) {
            const match = entries.filter((e) => e.value === key).sort((a, b) => a.priority - b.priority).pop();

            priority = match && match.priority || 0;
            priority++;
            entries.push({ value: key, priority });
        }

        return entries;

    }

}
