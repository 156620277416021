import { Injectable, inject } from '@angular/core';
import { Progress } from '@unifii/sdk';
import { Observable, of } from 'rxjs';

import { NavigationService } from 'shell/nav/navigation.service';
import { IndexedDbWrapper } from 'shell/offline/indexeddb-wrapper';
import { ContentPackage } from 'shell/offline/offline-model';
import { PostUpdateHook } from 'shell/offline/post-update-hook';
import { IndexesService } from 'unifii-helpdocs/services/indexes-service';

@Injectable()
export class UnifiiHelpDocsPostUpdateHook implements PostUpdateHook {

	private indexesService = inject(IndexesService);
	private navigationService = inject(NavigationService);

	run(nextContentDB: IndexedDbWrapper, content?: ContentPackage): Observable<Progress> {

		// Guard missing content or structure
		if (!content?.structure) {
			return of();
		}

		const entries = this.indexesService.getContentEntries(this.navigationService, content);

		return this.indexesService.storeEntries(nextContentDB, entries);

	}

}
