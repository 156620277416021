import { Injectable } from '@angular/core';

export interface HelpMenuItem {
	title: string;
	level: number;
	fragment: string;
}

@Injectable()
export class HeaderConverter {

	generateId(header: string): string {
		return header.replace(/[^0-9a-zA-Z ]/g, '')
			.replace(/ {2,}/g, ' ')
			.replace(/ /g, '-')
			.toLowerCase();

	}

	decode(value: string): string {
		return value
			.replace('&amp;', `&`)
			.replace('&gt;', `>`)
			.replace('&lt;', `<`)
			.replace('&#39;', `"`)
			.replace('&#39;', `'`);
	}

	generateHelpMenu(value: string): HelpMenuItem[] {
		const headers = this.findHeaders(value);

		return headers.map(({ title, level }) => {
			const fragment = this.generateId(title);

			return {
				title,
				level,
				fragment,
			};
		});
	}

	findHeaders(value: string): { title: string; level: number; description: string }[] {

		const headers = value.match(/^#+ [\w].*/gm) ?? [];

		return headers.map((header) => {

			const descriptionMatch = RegExp(/.+/).exec(value.substring(value.indexOf(header) + header.length).trim().replace(/[*#\[\]()]/g, ''));
			let description = '';

			if (descriptionMatch) {

				description = descriptionMatch[0];

				if (descriptionMatch[0].length > 200) {
					description = descriptionMatch[0].substring(0, 200) + '...';
				}

			}

			return {
				title: header.replace(/^#+ /, ''),
				level: header.replace(/[^#]/g, '').length,
				description,
			};

		});
	}

}
