import { Injectable, inject } from '@angular/core';

import { ErrorService } from 'shell/errors/error.service';
import { IndexedDbWrapper, KeyRange } from 'shell/offline/indexeddb-wrapper';

interface HelpDocsIDBObjectStore extends IDBObjectStore {
	getAllKeys(query?: KeyRange, count?: number): IDBRequest;
}

@Injectable()
export class HelpDocsIndexedDbWrapper extends IndexedDbWrapper {

	constructor() {
		const errorService = inject(ErrorService);

		super(errorService);
	}

	getAllKeys<T>(store: string, query?: KeyRange, count?: number): Promise<T> {
		return this.tx(store, (s) => this.promisify((s as HelpDocsIDBObjectStore).getAllKeys(query, count)));
	}

}
