import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProgressComponent, ThemeProvider, ThemeService, WindowWrapper } from '@unifii/library/common';
import { Option, StructureNode, Theme } from '@unifii/sdk';
import { Subject, Subscription } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { Config } from 'config';
import { AppUpdateService } from 'discover/app-update/app-update.service';
import { DiscoverContext } from 'discover/discover-context';
import { ShellService } from 'shell/core/shell.service';
import { NavigationService } from 'shell/nav/navigation.service';
import { OfflineQueue } from 'shell/offline/forms/offline-queue';
import { OfflineManager } from 'shell/offline/offline-manager';
import { ComponentTitleRouteData } from 'shell/shell-model';
import { HeaderConverter } from 'unifii-helpdocs/services/header-converter.service';
import { UnifiiHelpDocsContentSearcher } from 'unifii-helpdocs/unifii-helpdocs-content-searcher';

@Component({
	selector: 'uh-main',
	templateUrl: './main.html',
	styleUrls: ['./main.less'],
	standalone: false,
})
export class UnifiiHelpDocsMainComponent implements AfterViewInit, OnInit, OnDestroy {

	@ViewChild(ProgressComponent) private progressBar: ProgressComponent | undefined;

	protected shell = inject(ShellService);
	protected imgUrl: string;
	/** Search */
	protected showSearch: boolean;
	protected searchChange = new Subject<string>();
	protected searchOptions: Option[] = [];
	protected searchResult = new Subject<Option>();

	private title = inject(Title);
	private config = inject<Config>(Config);
	private nav = inject(NavigationService);
	private context = inject(DiscoverContext);
	private offlineQ = inject(OfflineQueue);
	private offlineManager = inject(OfflineManager);
	private window = inject<Window>(WindowWrapper);
	private router = inject(Router);
	private appUpdate = inject(AppUpdateService);
	private route = inject(ActivatedRoute);
	private translate = inject(TranslateService);
	private themeService = inject<ThemeService>(ThemeProvider);
	private contentSearcher = inject(UnifiiHelpDocsContentSearcher);
	private headerConverter = inject(HeaderConverter);

	private destroyed = new Subject<void>();
	private subscriptions = new Subscription();

	ngOnInit() {
		if (this.theme && !this.config.themeConfig?.disableProjectTheme) {
			this.themeService.theme = this.theme;
		}

		if (this.config.unifii.projectLogoUrl) {
			this.imgUrl = this.config.unifii.projectLogoUrl;
		}

		this.appUpdate.init();
		this.initTitles();
		this.initOfflineQ();
		this.initSearch();

		// clean up offline content (background async run)
		void this.offlineManager.cleanUp();

		// reset offline content notifications
		this.shell.reset('OfflineContent');

		// Update app progress
		this.subscriptions.add(this.shell.busyEvents.subscribe((e) => {

			if (this.progressBar != null && e) {
				this.progressBar.start();
			} else if (this.progressBar != null) {
				this.progressBar.complete();
			}
		}));
	}

	ngAfterViewInit() {

		/** Guard incase for angular universal */
		// eslint-disable-next-line disable-autofix/@typescript-eslint/no-unnecessary-condition
		if (!this.window.document) {
			return;
		}
	}

	ngOnDestroy() {
		this.destroyed.next();
		this.destroyed.complete();

		this.themeService.theme = this.config.themeConfig?.cssVariables ?? this.config.theme ?? {};

		this.subscriptions.unsubscribe();

		this.setTitles(); // Logout from project, reset Titlte
	}

	private initSearch() {

		this.showSearch = true;

		this.subscriptions.add(this.searchChange.subscribe((search) => {

			if (!search) {
				return;
			}

			this.contentSearcher.lookup(search).then((value) => {

				this.searchOptions = this.generateSearchOptions(value);

			}, (error) => {
				console.log(error);
			});

		}));

		this.subscriptions.add(this.searchResult.subscribe((res) => {
			void this.router.navigateByUrl(res.identifier);
		}));
	}

	private generateSearchOptions(value: string[]): Option[] {
		return value.reduce((options: Option[], res: string) => {

			const headerResult = res.split('_');
			const headerNodeId = headerResult[0];
			const headerName = headerResult[1];
			const headerDescription = headerResult[2];

			if (!headerName || !headerNodeId) {
				throw Error('invalid header');
			}

			const headerId = this.headerConverter.generateId(headerName);
			const headerNode = this.getNode(headerNodeId);

			if (!headerNode) {
				throw Error('headerNode not found');
			}

			if (!this.nav.canAccessNode(headerNode)) {
				return options;
			}

			return [...options, {
				identifier: `n/${headerNode.nodeId}/${headerNode.definitionIdentifier}#${headerId}`,
				name: `${headerName} (${headerNode.name})`,
				description: headerDescription,
			}];
		}, []);
	}

	private getNode(nodeId: string): StructureNode | null {
		return this.nav.getNode(nodeId);
	}

	/** Look up for navigation Title by following priority list:
     * 1. The StructureNode.name
     * 2. The Route data
     */
	private initTitles() {

		// eslint-disable-next-line disable-autofix/@typescript-eslint/non-nullable-type-assertion-style
		this.setTitles(this.nav.current as StructureNode);

		this.subscriptions.add(this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			map(() => this.nav.getNodeFromSnapshot(this.router.routerState.snapshot)))
			// eslint-disable-next-line disable-autofix/@typescript-eslint/non-nullable-type-assertion-style
			.subscribe((node) => this.setTitles(node as StructureNode)));
	}

	private setTitles(node?: StructureNode) {

		let title: string | undefined;

		if (node?.nodeId) {
			title = node.name;
		}

		if (!title) {
			let child = this.route.firstChild;

			while (child) {
				// Store title
				// eslint-disable-next-line disable-autofix/@typescript-eslint/no-unnecessary-condition
				if (child.snapshot.data && (child.snapshot.data).titleTranslationKey as ComponentTitleRouteData) {
					title = this.translate.instant(
						// eslint-disable-next-line disable-autofix/@typescript-eslint/non-nullable-type-assertion-style
						(child.snapshot.data as ComponentTitleRouteData).titleTranslationKey as string,
						(child.snapshot.data as ComponentTitleRouteData).titleParams,
					);
				}
				// Look for deeper route title (more specific)
				if (child.firstChild) {
					child = child.firstChild;
				} else {
					child = null;
				}
			}
		}

		if (!title) {
			title = this.context.project ? this.context.project.name : 'Unifii';
		}

		const prefix = this.config.env === 'prod' ? '' : this.config.env.toUpperCase() + ': ';

		this.shell.setTitle(title);
		this.title.setTitle(prefix + title);
	}

	private initOfflineQ() {

		this.subscriptions.add(this.offlineQ.additions.pipe(takeUntil(this.destroyed))
			.subscribe(() => { this.shell.notify('OfflineQ'); }));

		this.subscriptions.add(this.offlineQ.deletions.pipe(takeUntil(this.destroyed))
			.subscribe(() => { this.shell.done('OfflineQ'); }));

		void this.offlineQ.count().then((count) => { this.shell.reset('OfflineQ', count); });
		void this.offlineQ.prune();
	}

	private get theme(): Theme | undefined {
		return this.context.project?.theme;
	}

}
