import { Component, DestroyRef, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { DOMEventHandler, FilterValue, WindowWrapper } from '@unifii/library/common';
import { Dictionary, isNumber } from '@unifii/sdk';
import { Chart, ChartConfiguration, ChartData, ChartDataset, ChartOptions, ChartTypeRegistry, LegendItem, LegendOptions, LinearScaleOptions, Plugin, ScaleOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Options as ChartDataLabelsOptions } from 'chartjs-plugin-datalabels/types/options';
import { Subscription } from 'rxjs';

import { ReportColour, ReportColourCommon } from 'discover/reports/report-constants';
import { DataLabelContent, ReportAxisConfig, ReportConfig, ReportData, ReportDatalabelsConfig, ReportLegendConfig, ReportService } from 'discover/reports/report-service';
import { ChartComponent } from 'shell/common/chart/chart.component';
import { ShellTranslationKey } from 'shell/shell.tk';

import { afterDraw } from './pie-label-plugin';

@Component({
    selector: 'us-report',
    templateUrl: './report.html',
    styleUrls: ['./report.less'],
})
export class ReportComponent implements OnDestroy, OnInit {

	@Input() bottomThreshold = 50;

    protected readonly shellTK = ShellTranslationKey;

    protected chartConfig: ChartConfiguration;
    protected reportData?: ReportData;
    protected filters: Dictionary<FilterValue> = {};
    protected hideChart: boolean;

    private isTableExhausted = false;
    private _chart: ChartComponent | undefined;
    private _reportConfig: ReportConfig;
    private subscription = new Subscription();

    private service = inject(ReportService);
    private destroy = inject(DestroyRef);
    private window = inject<Window>(WindowWrapper);
    private domEventHandler = inject(DOMEventHandler);
    private debounceTimeout: NodeJS.Timer | undefined;
    private loading = false;

    ngOnInit() {
        Chart.defaults.font = {
            ...Chart.defaults.font,
            size: 11,
        };
        Chart.register(ChartDataLabels);

        this.domEventHandler.register({
			event: 'resize',
			listener: this.changeChartRatio.bind(this),
			destroy: this.destroy,
			debounceTime: 500,
		});
    }

    @ViewChild(ChartComponent) private set chart(chart: ChartComponent) {
        const init = !this._chart;

        this._chart = chart;
        if (init) {
            this.updateChartData();
        }
        this.changeChartRatio();
    }

    private get chart(): ChartComponent | undefined {
        return this._chart;
    }

    @Input() set reportConfig(v: ReportConfig) {
        this._reportConfig = v;
        this.updateReportConfig();
    }

    get reportConfig(): ReportConfig {
        return this._reportConfig;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    async loadData(filters: Dictionary<FilterValue>) {
        this.loading = true;
        this.filters = filters;
        try {
            if (this.reportConfig.chartType === 'table') {
                await this.loadPaginatedData(true);
            } else {
                this.reportData = await this.service.getData(this.reportConfig.identifier, filters);
                this.updateChartData();
            }

        } catch (e) {
            console.error('ReportComponent.loadData', e);
        } finally {
            this.loading = false;
        }

    }

    protected downloadTableAsCsv() {
        const rows: string[] = [this.reportData?.labels.join(',') ?? ''];

        for (const data of this.reportData?.datasets ?? []) {
            if (data.data) {
                rows.push(data.data.map((d) => (d.value as string | undefined ?? '')).join(','));
            }
        }

        const file = new Blob([rows.join('\n')], { type: 'text/csv' });
        const link = document.createElement('a');

        link.download = this.reportConfig.title + '.csv';

        link.href = window.URL.createObjectURL(file);

        link.click();
    }

    protected downloadChartAsImage() {
        this.chart?.downloadChartAsImage(this.reportConfig.title + '.png');
    }

    protected onScroll(event: Event) {
        if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = undefined;
        }

        this.debounceTimeout = setTimeout(() => {
            this.handleScroll(event)
            .catch((e) => {
                console.error(e);
            })
            .finally(() => {
                this.debounceTimeout = undefined;
            });
        }, 200);
    }

    private async handleScroll(event: Event) {
        const target = event.target as HTMLElement;
        const scrollPosition = target.scrollTop + target.offsetHeight;
        const isNearBottom = target.scrollHeight - scrollPosition <= this.bottomThreshold;

        if (!this.loading && !this.isTableExhausted && isNearBottom) {
           await this.loadPaginatedData(false);
        }
    }
    private async loadPaginatedData(initial: boolean) {
        try {
            let options: {
                offset: number;
                limit: number;
            } | undefined;

            let offset = 0;
            const limit = 50;

            if (this.reportConfig.chartType === 'table') {
                offset = initial ? 0 : this.reportData?.datasets.length ?? 0;

                options = {
                    offset,
                    limit,
                };
            }

            const data = await this.service.getData(this.reportConfig.identifier, this.filters, options);

            if (initial || !this.reportData) {
                this.reportData = data;
            } else {
                this.reportData.datasets = this.reportData.datasets.concat(data.datasets);
            }

            this.isTableExhausted = data.datasets.length < limit;

            this.updateChartData();
        } catch (e) {
            console.error('ReportComponent.loadPaginatedData', e);
        }
    }

    private updateHideChart() {
        this.hideChart = this.chartConfig?.type === 'pie' &&
            this.reportData?.datasets.every((dataset) => dataset.data == null || dataset.data.length === 0) === true;
    }

    private updateReportConfig() {
        if (this.reportConfig.chartType === 'table') {
            return;
        }

        this.chartConfig = {
            type: this.reportConfig.chartType,
            options: this.getChartOptions(this.reportConfig.chartType),
            plugins: this.getChartPlugins(this.reportConfig.chartType),
            data: {
                labels: [],
                datasets: [],
            },
        };
        this.updateHideChart();
    }

    private getScales(yAxis: ReportAxisConfig | undefined, xAxis: ReportAxisConfig | undefined): { x?: ScaleOptions; y?: ScaleOptions } | undefined {
        if (yAxis == null && xAxis == null) {
            return;
        }

        const scales: { x?: ScaleOptions; y?: ScaleOptions } = {};

        if (yAxis != null) {
            scales.y = this.getScaleOptions(yAxis);
        }
        if (xAxis != null) {
            scales.x = this.getScaleOptions(xAxis);
        }

        return scales;
    }

    private getScaleOptions(axisConfig: ReportAxisConfig): ScaleOptions {
        const axisOptions: ScaleOptions = {
            title: {
                display: !!axisConfig.label,
                text: axisConfig.label ?? '',
                font: {
                    weight: 'bold',
                },
            },
        };

        if (axisConfig.stacked != null) {
            axisOptions.stacked = axisConfig.stacked;
        }

        if (axisConfig.ticks != null) {
            axisOptions.ticks = axisConfig.ticks;
            axisOptions.min = axisConfig.ticks.min;
            axisOptions.max = axisConfig.ticks.max;
        }

        axisOptions.ticks = {
            ...axisOptions.ticks,
            color: 'black',
        };

        return axisOptions;
    }

    private getLegend<Type extends keyof ChartTypeRegistry>(type: keyof ChartTypeRegistry, reportLegendConfig?: ReportLegendConfig): Partial<LegendOptions<Type>> {

        const labels = { usePointStyle: true, pointStyle: 'rect', boxHeight: 20, boxWidth: 20, color: 'black', filter: (item: LegendItem, data: ChartData) => {
            return type !== 'pie';
        } };
        const display = (context: any) => context.chart.data.datasets.length > 1 || type === 'pie';

        if (reportLegendConfig == null) {
            const legend: Partial<LegendOptions<Type>> = { position: 'bottom' };

            // Chart js interface for labels doesn't allow monkey patching
            Object.assign(legend, { labels }, { display });

            // adding empty title as a workaround to adding padding between pie chart and legend to prevent overlapping
            const title = { display: true, padding: 20 };

            if (type === 'pie') {
                Object.assign(legend, { title });
            }

            return legend;
        }

        // TODO check the align override, should it be controlled by BE instead?
        return Object.assign(reportLegendConfig as Partial<LegendOptions<Type>>, { align: 'center', labels });
    }

    private getDatalabels(datalabels: ReportDatalabelsConfig = { display: false }): ChartDataLabelsOptions | undefined {
        return Object.assign(datalabels, { formatter: this.createDataLabelFormatter(datalabels.content) });
    }

    private createDataLabelFormatter(content?: DataLabelContent): ((value: any, context: any) => string | undefined) | undefined {
        switch (content) {
            case DataLabelContent.DatasetLabel: return ((_: any, context: any) => context?.dataset?.label as string | undefined);
            case DataLabelContent.DataLabel: return ((_: any, context: any) => ((context?.dataset?.labels || [])[context.dataIndex] || undefined) as string | undefined);
            case DataLabelContent.Value: return ((value: any) => value as string);
            case DataLabelContent.X: return ((value: any) => value?.x as string | undefined);
            case DataLabelContent.Y: return ((value: any) => value?.y as string | undefined);
            case DataLabelContent.R: return ((value: any) => value?.r as string | undefined);
            default: return undefined;
        }
    }

    private updateChartData() {

        this.updateHideChart();

        if (!this.reportData || !this.chart || this.hideChart || !this.chartConfig) {
            return;
        }

        const chartData: ChartData = {
            labels: this.reportData.labels as string[],
            datasets: this.reportData.datasets.map((dataset, index) => {
                const ds: any = {
                    label: dataset.label,
                    labels: dataset.labels,
                    data: dataset.data,
                    backgroundColor: this.getBackgroundColour(index, dataset.color),
                    borderColor: this.getBorderColour(index, dataset.color),
                    borderWidth: this.getBorderWidth(),
                    hoverBorderColor: this.getBorderColour(index, dataset.color),
                    hoverBorderWidth: this.getHoverBorderWidth(),
                    tension: dataset.tension ?? 0,
                    tooltips: dataset.tooltips,
                    maxBarThickness: 120,
                };

                if (this.chartConfig?.type === 'pie') {
                    ds.borderWidth = 1;
                    ds.polyline = {
                        formatter: (value: any) => `${value}`,
                    };
                }

                return ds as ChartDataset;
            }),
        };

       const suggestedMax = this.getMaxValue(chartData) + 1;

        this.chart.clearData();
        this.chart.addData(chartData);
        if (this.chartConfig.type === 'bar' && this.chartConfig.options?.scales) {
            (this.chartConfig.options.scales.y as LinearScaleOptions).suggestedMax = suggestedMax;
        }
    }

    private getBackgroundColour(index: number, colour?: string | string[]): string | string[] {
        return this.getColour(index, colour);
    }

    private getBorderColour(index: number, colour?: string | string[]): string | string[] | undefined {
        switch (this.reportConfig.chartType) {
            case 'bar':
            case 'pie':
            case 'doughnut':
                return '#ffffff';
            case 'polarArea':
            case 'scatter':
            case 'bubble':
                return undefined;
            default:
                return this.getColour(index, colour);
        }
    }

    private getBorderWidth(): number | { top: number; bottom: number; left: number; right: number } | undefined {
        switch (this.reportConfig.chartType) {
            case 'bar':
                return {
                    top: 1,
                    bottom: 0,
                    left: 0,
                    right: 0,
                };
            case 'polarArea':
            case 'scatter':
            case 'bubble':
                return undefined;
            default:
                return 1;
        }
    }

    private getChartOptions(type: keyof ChartTypeRegistry) {
        const options: ChartOptions = {
            scales: this.getScales(this.reportConfig.yAxis, this.reportConfig.xAxis),
            plugins: {
                legend: this.getLegend(type, this.reportConfig.legend),
                datalabels: this.getDatalabels(this.reportConfig.datalabels),
                tooltip: {
                    callbacks: {
                        label: (context: any) => {
                            const customTooltip = (context.dataset?.tooltips || [])[context.dataIndex];

                            return customTooltip || `${context.dataset?.label ?? ''}: ${context.formattedValue}`;
                        },
                    },
                },
            },
            responsive: true,
            maintainAspectRatio: false,
            layout: {
                padding: {
                    top: 20,
                },
            },
        };

        if (type === 'pie') {
            options.layout = {
                padding: {
                    top: 50,
                    left: 50,
                    right: 50,
                    bottom: 20,
                },
            };
        }

        return options;
    }

    private getChartPlugins(type: keyof ChartTypeRegistry): Plugin<any>[] {

        const plugins: Plugin<any>[] = [{
            id: 'custom_canvas_background_color',
            beforeDraw: (chart: Chart) => {
                const { ctx } = chart;

                ctx.save();
                ctx.globalCompositeOperation = 'destination-over';
                ctx.fillStyle = '#fff';
                ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.restore();
            },
        }];

        if (type === 'pie') {
            plugins.push({
                id: 'pie-custom-labels',
                afterDraw,
            });
        }

        return plugins;
    }

    private getHoverBorderWidth(): number {
        return this.getBorderWidth() ? 1 : 0;
    }

    // look up in 2 dictionaries or return itself, if no colour supplied return random
    private getColour(index: number, colour?: string | string[]): string | any[] {
        if (!colour) {
            return ReportColourCommon[index % Object.keys(ReportColourCommon).length] as unknown as string;
        }

        if (Array.isArray(colour)) {
            return colour.map((c, i) => this.getColour(i, c));
        }

        return ReportColour[colour] ?? ReportColourCommon[colour] ?? colour;
    }

    private changeChartRatio() {
        const windowWidth = this.window.innerWidth;
        let ratio = 2;

        if (windowWidth < 768) {
            ratio = 4/3;
        }
        if (this.chart) {
            this.chart.changeRatio(ratio);
        }
    }

    private getMaxValue(reportData: ChartData): number {

        const result = reportData.datasets.reduce((acc: number[], dataset) => {
            for (const [index, value] of dataset.data.entries()) {
                if (isNumber(value)) {
                    acc[index] = (acc[index] ?? 0) + value;
                }
            }

            return acc;
        }, []);

        return Math.max(...result);
    }

}
