<us-shell>
	<us-header #header [menuDrawer]="menu" [rightDrawer]="right" [title]="shell.title">
		@if (showSearch) {
			<uf-search-result [options]="searchOptions" [clearOnSelect]="false" [blurOnSelect]="true" (searchChange)="searchChange.next($event)" (valueChange)="searchResult.next($event)" class="search" placeholder="Search" nameProperty="name">
				<ng-template #listBox let-options="options" let-select="select" let-active="active">
					<ul class="uf-list help-search-results">
						@for (option of options; track option; let i = $index) {
							<li [class.active]="i === active" (click)="select(option)" class="pad-left pad-right clickable">
								<h4>{{ option.name }}</h4>
								<p class="small">{{ option.description }}</p>
							</li>
						}
					</ul>
				</ng-template>
			</uf-search-result>
		}
	</us-header>
	<us-menu-drawer #menu>
		<us-nav [img]="imgUrl" />
	</us-menu-drawer>
	<us-drawer #right (closed)="shell.closeRightDrawer()" icon="settings">
		<uh-settings />
	</us-drawer>
	<router-outlet />
</us-shell>
<ud-icons />
<uf-progress class="large" />
