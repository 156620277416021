import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { ContentNodeComponent } from 'shell/content/content-node.component';
import { ShellRouteReuseStrategy } from 'shell/route-reuse-strategy';

@Injectable()
export class UnifiiHelpDocsRouteReuseStrategy extends ShellRouteReuseStrategy {

	override shouldReuseRoute(active: ActivatedRouteSnapshot, next: ActivatedRouteSnapshot): boolean {

		switch (next.component) {
			case ContentNodeComponent:

				if (active.fragment !== next.fragment) {
					return active.url.join('') === next.url.join('');
				}

				return false;
		}

		return active.routeConfig === next.routeConfig;
	}

}
