<us-shell>
	<us-header #header [menuDrawer]="menu" [rightDrawer]="right" [title]="shell.title">
		<uf-search-result class="search" *ngIf="showSearch" placeholder="Search" [options]="searchOptions"
			[clearOnSelect]="false" [blurOnSelect]="true" nameProperty="name" (searchChange)="searchChange.next($event)"
			(valueChange)="searchResult.next($event)">
			<ng-template #listBox let-options="options" let-select="select" let-active="active">
				<ul class="uf-list help-search-results">
					<li class="pad-left pad-right clickable" *ngFor="let option of options; let i = index" (click)="select(option)"
						[class.active]="i === active">
						<h4>{{ option.name }}</h4>
						<p class="small">{{option.description}}</p>
					</li>
				</ul>
			</ng-template>

		</uf-search-result>
	</us-header>
	<us-menu-drawer #menu>
		<us-nav [img]="imgUrl"></us-nav>
	</us-menu-drawer>
	<us-drawer #right icon="settings" (closed)="shell.closeRightDrawer()">
		<uh-settings></uh-settings>
	</us-drawer>
	<router-outlet></router-outlet>
</us-shell>
<ud-icons></ud-icons>
<uf-progress class="large"></uf-progress>