import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { OnlineManager } from './online-manager';

export const onlineIndexGuard: CanActivateFn = () => new OnlineIndexGuard(
	inject(OnlineManager),
).canActivate();

class OnlineIndexGuard {

	constructor(
		private onlineManager: OnlineManager,
	) { }

	async canActivate(): Promise<boolean> {

		try {
			await this.onlineManager.openContentDB();

			return await this.onlineManager.updateContent();
		} catch (error) {
			console.warn('OnlineGuard: no content available, update needed', error);

			return this.onlineManager.updateContent();
		}
	}

}
