import { Injectable, inject } from '@angular/core';
import { MarkdownProvider, WindowWrapper } from '@unifii/library/common';
import { patchAssets } from '@unifii/markdown-it-assets';

import { HeaderConverter } from './header-converter.service';

// eslint-disable-next-line @typescript-eslint/no-require-imports
const markdown = require('markdown-it')('commonmark');

patchAssets(markdown);
@Injectable()
export class HyperlinkRender implements MarkdownProvider {

	private window = inject<Window>(WindowWrapper);
	private headerConverter = inject(HeaderConverter);

	render(v = ''): string {

		let html = '';

		try {
			html = markdown.render(v) || '';
		} catch {
			console.error('Markdown parse exception', v);

			return '';
		}

		const headerPattern = /<h\d>(\w|\d|\n|[().,\-:;@#$%^&*\[\]"'+–/\/®°⁰!?{}|`~]| )+?<\/h\d>/g;

		const matches = html.match(headerPattern);

		for (const match of matches ?? []) {

			// split tag
			let startTag = (RegExp(/<h\d>/).exec(match) ?? [''])[0];
			const endTag = (RegExp(/<\/h\d>/).exec(match) ?? [''])[0];
			const content = this.headerConverter.decode(match.replace(/<\/?h\d>/g, ''));

			// generate id by removing symbols and spaces
			const id = this.headerConverter.generateId(content);

			// add id
			startTag = startTag.slice(0, startTag.length - 1) + ` id=${id}` + startTag.slice(startTag.length - 1, startTag.length);

			// add anchor
			const url = this.getCurrentUrl();
			const anchor = `<a class="anchor" href="${url}#${id}"></a>`;

			// replace original header
			html = html.replace(match, startTag + content + anchor + endTag);
		}

		return html;
	}

	private getCurrentUrl(): string {
		// when you go back, the hash stays in the url so must be removed
		return this.window.location.origin + this.window.location.pathname;
	}

}
