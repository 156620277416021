import { Component, OnInit, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';
import { Progress } from '@unifii/sdk';
import { Observable, Subscription } from 'rxjs';

import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';

export interface IndexProgressData {
	progress: Observable<Progress>;
}

@Component({
	selector: 'uh-index-progress',
	templateUrl: './index-progress.html',
	styleUrls: ['./index-progress.less'],
	standalone: false,
})
export class IndexProgressComponent implements Modal<IndexProgressData, boolean>, OnInit {

	runtime = inject<ModalRuntime<IndexProgressData, boolean>>(ModalRuntime);
	data = inject<{ progress: Observable<Progress> }>(ModalData);

	protected version: string | null;
	protected progress = 0;
	protected error: AppError | undefined;

	private errorService = inject(ErrorService);
	private subscription: Subscription | undefined;

	ngOnInit() {
		this.update();
	}

	update() {

		if (this.subscription) {
			this.subscription.unsubscribe();
		}

		this.subscription = this.data.progress.subscribe(
			(progress) => {
				this.progress = progress.done / progress.total;
				this.version = progress.id ?? null;
			},
			(error) => {
				// todo: translate error message!
				this.error = this.errorService.createError('Index failed to updated', error);
			},
			() => this.close());
	}

	close() {
		this.runtime.close(this.error == null);
	}

}
