import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';

import { OnlineManager } from './online-manager';

export const onlineIndexGuard: CanActivateFn = async(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => new OnlineIndexGuard(
    inject(OnlineManager),
).canActivate(route, state);

class OnlineIndexGuard {

    constructor(
        private onlineManager: OnlineManager,
    ) { }

    async canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {

        try {
            await this.onlineManager.openContentDB();

            return this.onlineManager.updateContent();
        } catch (error) {
            console.warn('OnlineGuard: no content available, update needed', error);

            return this.onlineManager.updateContent();
        }
    }

}
