import { Injectable } from '@angular/core';
import { FieldType, Progress, StructureNodeType } from '@unifii/sdk';
import { Observable, ReplaySubject } from 'rxjs';

import { NavigationService } from 'shell/nav/navigation.service';
import { ContentIndexer } from 'shell/offline/content-indexer';
import { IndexedDbWrapper } from 'shell/offline/indexeddb-wrapper';
import { ContentPackage } from 'shell/offline/offline-model';
import { HeaderConverter } from 'unifii-helpdocs/services/header-converter.service';

const excludeSearchTag = 'excludeSearch';

@Injectable()
export class IndexesService {

    static readonly INDEX_ENTRIES_TOKEN = 'UHIndexEntries';

    availableChanged: ReplaySubject<boolean> = new ReplaySubject(1);

    private _available = true;

    constructor(private headerConverter: HeaderConverter) {

    }

    get available(): boolean {
        return this._available;
    }

    set available(v: boolean) {
        this._available = v;
        this.availableChanged.next(this._available);
    }

    getContentEntries(navigation: NavigationService, content: ContentPackage): { key: string; value: string }[] {

        navigation.structure = content.structure || null;

        const entries: { key: string; value: string }[] = [];

        // create index content
        for (const node of navigation.getDescendants(navigation.structure)) {

            if (node.type === StructureNodeType.Page && node.nodeId && node.id) {

                const page = this.addPage(node.nodeId, node.id, content);

                if (page) {
                    entries.push(...page);
                }
            }
        }

        return entries;
    }

    storeEntries(contentDB: IndexedDbWrapper, entries: { key: string; value: string }[]): Observable<Progress> {

        return new Observable<Progress>((observer) => {
            const indexer = new ContentIndexer(contentDB);
            let done = 0;
            let promise = Promise.resolve();

            for (const entry of entries) {
                promise = promise.then(() => {
                    done++;
                    observer.next({ done, total: entries.length });

                    return indexer.index(entry.key, entry.value);
                });
            }

            promise.then(() => {
                observer.complete();
            });
        });
    }

    private addPage(nodeId: string, id: number, content: ContentPackage): { key: string; value: string }[] | null {

        const page = content.pages.find((p) => Number(p.id) === Number(id));

        if (!page) {
            return null;
        }

        // omit pages with exclude tag
        if (page.tags?.includes(excludeSearchTag)) {
            return null;
        }

        const result: { key: string; value: string }[] = [];

        for (const field of page.fields) {

            if (field.type !== FieldType.MultiText) {
                continue;
            }

            const headers = this.headerConverter.findHeaders(field.value ?? '');

            result.push(...headers.map((header) => ({ key: `${nodeId}_${header.title}_${header.description}`, value: header.title })));

        }

        return result;
    }

}
