import { stemmer, stopWordFilter, tokenizer, trimmer } from 'lunr';

export const getTokens = (text: string): string[] => {
	if (!text) {
		return [];
	}

	return tokenizer(text)
		.filter((v, i, self) => self.indexOf(v) === i)
		.map((i) => trimmer(i.clone()))
		.filter((i) => stopWordFilter(i.clone()) != null)
		.map((i) => stemmer(i.clone()))
		.map((i) => i.toString().trim())
		.filter((i) => i !== '');
};
