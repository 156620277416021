<div class="page-container">
    <uf-panel class="container">
        <div class="page-wrapper">
            <uf-page [page]="page" />
        </div>

        <div *ngIf="navItems.length" class="desktop-nav-wrapper">
            <uf-nav [navItems]="navItems" (selected)="navigateTo($event)" />
        </div>

        <div class="horizontal-spacer"></div>
    </uf-panel>

    <uf-drawer *ngIf="navItems.length" [showHandle]="true" class="mobile-nav-wrapper">
        <uf-nav [navItems]="navItems" (selected)="navigateTo($event)" />
    </uf-drawer>
</div>