import { DOCUMENT } from '@angular/common';
import { Component, OnDestroy, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MarkdownProvider, NavItem, RuntimePage } from '@unifii/library/common';
import { UfPageComponent } from '@unifii/library/smart-forms/display';
import { FieldType } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { PageContent } from 'shell/content/content-types';
import { HeaderConverter, HelpMenuItem } from 'unifii-helpdocs/services/header-converter.service';
import { HyperlinkRender } from 'unifii-helpdocs/services/hyperlink-render';

@Component({
	selector: 'uh-custom-page',
	templateUrl: './custom-page.html',
	styleUrls: ['./custom-page.less'],
	providers: [
		{ provide: MarkdownProvider, useClass: HyperlinkRender },
	],
	standalone: false,
})
export class CustomPageComponent implements OnDestroy, PageContent {

	page: RuntimePage;
	title: string;

	protected navItems: NavItem[] = [];

	private navItemLookupByFragment = new Map<string, NavItem>();
	private lookupFragmentByNavItem = new WeakMap<NavItem, string>();
	private _pageComponent: UfPageComponent | undefined;
	private subscription: Subscription | undefined;
	private fragment: string;

	private document = inject(DOCUMENT);
	private router = inject(Router);
	private headerConverter = inject(HeaderConverter);
	private route = inject(ActivatedRoute);

	ngOnDestroy() {
		this.subscription?.unsubscribe();
	}

	@ViewChild(UfPageComponent) set pageComponent(v: UfPageComponent | undefined) {

		if (!v || this._pageComponent != null) {
			return;
		}

		this._pageComponent = v;

		this.subscription?.unsubscribe();

		this.subscription = this.route.fragment.subscribe((fragment) => {
			if (fragment) {
				this.fragment = fragment;
				this.scroll();
			}
		});

		this.createHelpMenu(this.page);
	}

	protected navigateTo(item: NavItem) {

		const previousItem = this.navItemLookupByFragment.get(this.fragment);

		if (previousItem) {
			previousItem.isActive = false;
		}

		item.isActive = true;
		const fragment = this.lookupFragmentByNavItem.get(item);

		void this.router.navigate([], { fragment });

	}

	private getParentCount(level: number): number {
		if (level === 1) {
			return 0;
		}

		return [2, 3].includes(level) ? 1 : 3;
	}

	private createHelpMenu(page: RuntimePage | undefined) {

		if (page == null) {
			return;
		}

		const menuItems: HelpMenuItem[] = page.fields
			.filter((field) => field.type === FieldType.MultiText)
			.map((field) => this.headerConverter.generateHelpMenu(field.value))
			.reduce<HelpMenuItem[]>((result, items) => {
				result.push(...items);

				return result;
			}, []);

		for (const item of menuItems) {

			const navItem: NavItem = {
				label: item.title,
				parentCount: this.getParentCount(item.level),
				isRegistered: true,
				isActive: this.fragment === item.fragment,
				isDisabled: false,
				isAccented: false,
				hasError: false,
				isHeading: false,
				hasSuccess: false,
			};

			this.navItems.push(navItem);
			this.navItemLookupByFragment.set(item.fragment, navItem);
			this.lookupFragmentByNavItem.set(navItem, item.fragment);
		}
	}

	private scroll() {

		try {
			if (this.fragment === '') {
				return;
			}

			const element = this.document.querySelector(`#${this.fragment}`);

			if (!element) {
				return;
			}

			element.scrollIntoView();

		} catch (err) {
			console.log('Scroll Failed', { error: err });
		}

	}

}
