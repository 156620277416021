import { DOCUMENT } from '@angular/common';
import { Component, ViewChild, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MarkdownProvider, RuntimeDefinition } from '@unifii/library/common';
import { UfViewComponent } from '@unifii/library/smart-forms/display';
import { Compound } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { ViewContent } from 'shell/content/content-types';
import { HyperlinkRender } from 'unifii-helpdocs/services/hyperlink-render';

@Component({
	selector: 'uh-custom-view',
	templateUrl: './custom-view.html',
	styleUrls: ['./custom-page.less'],
	providers: [
		{ provide: MarkdownProvider, useClass: HyperlinkRender },
	],
	standalone: false,
})
export class CustomViewComponent implements ViewContent {

	definition: RuntimeDefinition;
	compound: Compound;
	title: string;

	private _viewComponent: UfViewComponent | undefined;
	private subscriptions = new Subscription();
	private fragment: string;

	private document = inject(DOCUMENT);
	private route = inject(ActivatedRoute);

	@ViewChild(UfViewComponent) set viewComponent(v: UfViewComponent | undefined) {

		if (!v || this._viewComponent != null) {
			return;
		}

		this._viewComponent = v;

		this.subscriptions.add(this.route.fragment.subscribe((fragment) => {
			if (fragment) {
				this.fragment = fragment;
				this.scroll();
			}
		}));
	}

	private scroll() {

		try {
			if (this.fragment === '') {
				return;
			}

			const element = this.document.querySelector(`#${this.fragment}`);

			if (!element) {
				return;
			}

			element.scrollIntoView();

		} catch (err) {
			console.log('Scroll Failed', { error: err });
		}

	}

}
